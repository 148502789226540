import Const from '../constants'
import axiosApiInstance from '../interceptors'

class QuestionAndAnswerService {
  getQandA() {
    return axiosApiInstance.get(Const.api_url + 'qna/frequent')
  }
}

export default new QuestionAndAnswerService()
