



























































import { Component, Vue } from 'vue-property-decorator'
import LayoutSideBar from '@/layouts/LayoutSideBar.vue'
import QuestionAndAnswerService from '@/services/QuestionAndAnswerService'

@Component({
  components: {
    LayoutSideBar
  }
})
export default class QuestionAndAnswer extends Vue {
  public QAcategorys: any = []
  public QaAs: any = []

  created() {
    this.questions()
  }

  private async questions(): Promise<void> {
    await QuestionAndAnswerService.getQandA().then(response => {
      if (response) {
        this.QAcategorys = response.data.items
      }
    })
  }

  public scollTo(refName: any) {
    let element: any = this.$refs[refName]
    let headerHeight: any = document.getElementById('header-page')
    let top: any = element[0].offsetTop - headerHeight.offsetHeight - 5
    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    })
  }
}
